// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  height: auto;
}

#main-outer-div {
  position: relative;
  background-color: #fff;
  width: 80%;
  max-width: 400px;
  margin: 7% auto;
  padding: 30px;
  border-radius: 10px;
}

#logo-div {
  width: 24%;
  margin: auto;
}

#title-div {
  text-align: center;
}

.login-form-input {
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  height: 40px;
  margin: 10px 0;
  background-color: #eef3ff;
  border: none;
  padding: 20px;
}

.login-form-input:focus {
  outline: none;
}

#outer-login-form-div {
  width: 90%;
  margin: auto;
}

.login-form-input[type="submit"] {
  background-color: #395faf;
  border: none;
  margin-top: 15px;
}

.loading {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.alert {
  font-size: 14px;
  text-align: center;
  color: red;
}

@media (max-width: 300px) {
  #main-outer-div {
    width: 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,cAAc;EACd,yBAAyB;EACzB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":["#root {\n  height: auto;\n}\n\n#main-outer-div {\n  position: relative;\n  background-color: #fff;\n  width: 80%;\n  max-width: 400px;\n  margin: 7% auto;\n  padding: 30px;\n  border-radius: 10px;\n}\n\n#logo-div {\n  width: 24%;\n  margin: auto;\n}\n\n#title-div {\n  text-align: center;\n}\n\n.login-form-input {\n  box-sizing: border-box;\n  width: 100%;\n  border-radius: 10px;\n  height: 40px;\n  margin: 10px 0;\n  background-color: #eef3ff;\n  border: none;\n  padding: 20px;\n}\n\n.login-form-input:focus {\n  outline: none;\n}\n\n#outer-login-form-div {\n  width: 90%;\n  margin: auto;\n}\n\n.login-form-input[type=\"submit\"] {\n  background-color: #395faf;\n  border: none;\n  margin-top: 15px;\n}\n\n.loading {\n  text-align: center;\n  margin-top: 15px;\n}\n\n.title {\n  font-size: 20px;\n  font-weight: 600;\n  text-align: center;\n}\n\n.alert {\n  font-size: 14px;\n  text-align: center;\n  color: red;\n}\n\n@media (max-width: 300px) {\n  #main-outer-div {\n    width: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
