// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body{
    background-color: #f7fafc;
    height: 100%;
    width: 100%;
}

.logo{
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.logo_font{
    font-size: 40px;
    font-weight: 700;
}

.sub_title{
    text-align: left;
    font-size: 24px;
    font-weight: 800;
}
.sub_title_txt{
    font-size: 18px;
    text-align: left;
}

.list_style > li{
    font-size: 17px;
    font-weight: 500;
    margin: 10px 0px;
    list-style-type: disc;
}

.selector {
    height: 50px !important;
    margin: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/landinPage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;AAC1B","sourcesContent":[".body{\n    background-color: #f7fafc;\n    height: 100%;\n    width: 100%;\n}\n\n.logo{\n    width: 70px;\n    height: 70px;\n    object-fit: cover;\n}\n\n.logo_font{\n    font-size: 40px;\n    font-weight: 700;\n}\n\n.sub_title{\n    text-align: left;\n    font-size: 24px;\n    font-weight: 800;\n}\n.sub_title_txt{\n    font-size: 18px;\n    text-align: left;\n}\n\n.list_style > li{\n    font-size: 17px;\n    font-weight: 500;\n    margin: 10px 0px;\n    list-style-type: disc;\n}\n\n.selector {\n    height: 50px !important;\n    margin: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
